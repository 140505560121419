export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/error/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },

  //  Blog
  {
    path: '/pages/blog/BloggersBlogList',
    name: 'pages-blog-bloggers-blog-list',
    component: () => import('@/views/pages/blog/BloggersBlogList.vue'),
    meta: {
      resource: 'Blogger',
      action: 'Blogger',
    },
  },
  {
    path: '/pages/blog/list',
    name: 'pages-blog-list',
    component: () => import('@/views/pages/blog/BlogList.vue'),
    meta: {
      resource: 'Blogger',
      action: 'Blogger',
    },
  },
  {
    path: '/pages/blog/blog-content/create-blog-content',
    name: 'pages-blog-blog-content-create-blog-content',
    component: () => import('@/views/pages/blog/blog-content/create-blog-content.vue'),
    meta: {
      resource: 'Blogger',
      action: 'Blogger',
    },
  },
  {
    path: '/pages/blog/blog-content/edit-blog-content',
    name: 'pages-blog-blog-content-edit-blog-content',
    component: () => import('@/views/pages/blog/blog-content/edit-blog-content.vue'),
    meta: {
      resource: 'Blogger',
      action: 'Blogger',
    },
  },
  {
    path: '/pages/blog/comments',
    name: 'pages-blog-comments',
    component: () => import('@/views/pages/blog/comments/BlogComments.vue'),
    meta: {
      resource: 'Blogger',
      action: 'Blogger',
    },
  },
  {
    path: '/pages/blog/comments/:id',
    name: 'pages-blog-comments-edit',
    component: () => import('@/views/pages/blog/comments/BlogCommentEdit.vue'),
    meta: {
      resource: 'Blogger',
      action: 'Blogger',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/pages/blog/create',
    name: 'pages-blog-create',
    component: () => import('@/views/pages/blog/BlogCreate.vue'),
    meta: {
      resource: 'Blogger',
      action: 'Blogger',
    },
  },
  {
    path: '/pages/blog/edit/:id',
    name: 'pages-blog-edit',
    component: () => import('@/views/pages/blog/BlogEdit.vue'),
    meta: {
      resource: 'Blogger',
      action: 'Blogger',
    },
  },
  {
    path: '/pages/blog/categories/list',
    name: 'pages-blog-categories-list',
    component: () => import('@/views/pages/blog/categories/categories-list.vue'),
    meta: {
      resource: 'Blogger',
      action: 'Blogger',
    },
  },
  {
    path: '/pages/blog/categories/edit/:id',
    name: 'pages-blog-categories-edit',
    component: () => import('@/views/pages/blog/categories/categories-edit.vue'),
    meta: {
      resource: 'Blogger',
      action: 'Blogger',
    },
  },
]
