export default [

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/currencies/manage-currencies',
    name: 'apps-currencies-manage-currencies',
    component: () => import('@/views/apps/currencies/manage-currencies.vue'),
    title: 'manage currencies',
  },
  {
    path: '/apps/games/manage-games',
    name: 'apps-games-manage-games',
    component: () => import('@/views/apps/games/manage-games.vue'),
    title: 'manage games',
  },
  {
    path: '/apps/games/manage-game',
    name: 'apps-games-manage-game',
    component: () => import('@/views/apps/games/manage-game.vue'),
    title: 'manage game',
  },
  {
    path: '/apps/games/manage-platforms',
    name: 'apps-games-manage-platforms',
    component: () => import('@/views/apps/games/manage-platforms.vue'),
    title: 'manage platforms',
  },
  {
    path: '/apps/games/manage-offers',
    name: 'apps-games-manage-offers',
    component: () => import('@/views/apps/games/manage-offers.vue'),
    title: 'manage offers',
  },
  {
    path: '/apps/games/game-service-categories/create-game-service-category',
    name: 'apps-games-game-service-categories-create-game-service-category',
    component: () => import('@/views/apps/games/game-service-categories/create-game-service-category.vue'),
    title: 'create game service category',
  },
  {
    path: '/apps/games/game-service-categories/manage-game-service-category',
    name: 'apps-games-game-service-categories-manage-game-service-category',
    component: () => import('@/views/apps/games/game-service-categories/manage-game-service-category.vue'),
    title: 'manage game service category',
  },
  {
    path: '/apps/games/game-service-categories/game-service/manage-game-services',
    name: 'apps-games-game-service-categories-game-service-manage-game-services',
    component: () => import('@/views/apps/games/game-service-categories/game-service/manage-game-services.vue'),
    title: 'manage game service',
  },
  {
    path: '/apps/games/game-service-categories/game-service/create-game-service',
    name: 'apps-games-game-service-categories-game-service-create-game-service',
    component: () => import('@/views/apps/games/game-service-categories/game-service/create-game-service.vue'),
    title: 'create game service',
  },
  {
    path: '/apps/games/game-service-categories/game-service/game-service-content/create-game-service-content',
    name: 'apps-games-game-service-categories-game-service-game-service-content-create-game-service-content',
    component: () => import('@/views/apps/games/game-service-categories/game-service/game-service-content/create-game-service-content.vue'),
    title: 'create game service content',
  },
  {
    path: '/apps/games/game-service-categories/game-service/game-service-content/edit-game-service-content',
    name: 'apps-games-game-service-categories-game-service-game-service-content-edit-game-service-content',
    component: () => import('@/views/apps/games/game-service-categories/game-service/game-service-content/edit-game-service-content.vue'),
    title: 'update game service content',
  },
  {
    path: '/apps/games/game-service-categories/game-service/products/create-product',
    name: 'apps-games-game-service-categories-game-service-products-create-product',
    component: () => import('@/views/apps/games/game-service-categories/game-service/products/create-product.vue'),
    title: 'create game service product',
  },
  {
    path: '/apps/games/game-service-categories/game-service/products/product-field/create-product-field',
    name: 'apps-games-game-service-categories-game-service-products-product-field-create-product-field',
    component: () => import('@/views/apps/games/game-service-categories/game-service/products/product-field/create-product-field.vue'),
    title: 'create product field',
  },
  {
    path: '/apps/games/game-service-categories/game-service/products/product-field/manage-product-field',
    name: 'apps-games-game-service-categories-game-service-products-product-field-manage-product-field',
    component: () => import('@/views/apps/games/game-service-categories/game-service/products/product-field/manage-product-field.vue'),
    title: 'manage product field',
  },
  {
    path: '/apps/games/game-service-categories/game-service/products/product-field/product-feature/create-product-feature',
    name: 'apps-games-game-service-categories-game-service-products-product-field-product-feature-create-product-feature',
    component: () => import('@/views/apps/games/game-service-categories/game-service/products/product-field/product-feature/create-product-feature.vue'),
    title: 'create product feature',
  },
  {
    path: '/apps/games/game-service-categories/game-service/products/product-field/product-feature/edit-product-feature',
    name: 'apps-games-game-service-categories-game-service-products-product-field-product-feature-edit-product-feature',
    component: () => import('@/views/apps/games/game-service-categories/game-service/products/product-field/product-feature/edit-product-feature.vue'),
    title: 'edit product feature',
  },
  {
    path: '/apps/games/game-service-categories/game-service/products/product-slider/manage-slider',
    name: 'apps-games-game-service-categories-game-service-products-product-slider-manage-slider',
    component: () => import('@/views/apps/games/game-service-categories/game-service/products/product-slider/manage-slider.vue'),
    title: 'manage slider',
  },
  {
    path: '/apps/games/game-service-categories/game-service/products/manage-product',
    name: 'apps-games-game-service-categories-game-service-products-manage-product',
    component: () => import('@/views/apps/games/game-service-categories/game-service/products/manage-product.vue'),
    title: 'manage game service product',
  },
  {
    path: '/apps/games/create-game',
    name: 'apps-games-create-game',
    component: () => import('@/views/apps/games/create-game.vue'),
    title: 'create game',
  },

  {
    path: '/apps/orders/order-chat/:orderCode',
    name: 'apps-orders-order-chat',
    // resource: 'Support',
    // action: 'Support',
    component: () => import('@/views/apps/orders/OrderChat.vue'),
    // meta: {
    //   // contentRenderer: 'sidebar-left',
    //   contentClass: 'chat-application',
    // },
  },
  {
    path: '/apps/game-reviews/list',
    name: 'apps-game-reviews-list',
    component: () => import('@/views/apps/game-review/list.vue'),
  },
  {
    path: '/apps/site-setting/general-setting',
    name: 'apps-site-setting-general-setting',
    component: () => import('@/views/apps/site-setting/general-setting.vue'),
  },
  //  Ticket
  {
    path: '/apps/allTickets',
    name: 'apps-allTickets',
    component: () => import('@/views/apps/Ticket/AllTickets.vue'),
  },
  {
    path: '/apps/ticketDetail/:id',
    name: 'apps-ticketDetail-id',
    component: () => import('@/views/apps/Ticket/Detail/TicketDetail.vue'),
  },

  //  User
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/pro-lists',
    name: 'apps-users-pro-lists',
    component: () => import('@/views/apps/user/pro-list/ProLists.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },
  {
    path: '/apps/users/create-user',
    name: 'apps-users-create-user',
    component: () => import('@/views/apps/user/create-user.vue'),
    title: 'create user'
  },

  {
    path: '/apps/orders/list',
    name: 'apps-orders-list',
    component: () => import('@/views/apps/orders/OrdersList.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },
  {
    path: '/apps/orders/warranties',
    name: 'apps-orders-warranties',
    component: () => import('@/views/apps/orders/OrderWarranties.vue'),

  },
  {
    path: '/apps/orders/info/:id',
    name: 'apps-orders-info',
    component: () => import('@/views/apps/orders/OrderInfo.vue'),
  },
  {
    path: '/apps/orders/warranties/warrantyInfo/:id',
    name: 'apps-orders-warranties-warrantyInfo',
    component: () => import('@/views/apps/orders/WarrantyInfo.vue'),
  },
  //  Wallets
  {
    path: '/apps/payments/list',
    name: 'apps-payments-list',
    component: () => import('@/views/apps/payments/list.vue'),
  },
  {
    path: '/apps/wallets/manage-wallets',
    name: 'apps-wallets-manage-wallets',
    component: () => import('@/views/apps/wallets/WalletsList.vue'),
  },
  {
    path: '/apps/wallets/wallet-info/:id',
    name: 'apps-wallets-wallet-info',
    component: () => import('@/views/apps/wallets/WalletInfo.vue'),
  },
  // site setting
  {
    path: '/apps/site-setting/faqs/faqs',
    name: 'apps-site-setting-faqs-faqs',
    component: () => import('@/views/apps/faqs/faqs.vue'),
  },
  {
    path: '/apps/site-setting/faqs/create',
    name: 'apps-site-setting-faqs-create-faq',
    component: () => import('@/views/apps/faqs/create-faq.vue'),
  },
  {
    path: '/apps/site-setting/faqs/update/:id',
    name: 'apps-site-setting-faqs-update-faq',
    component: () => import('@/views/apps/faqs/update-faq.vue'),
  },
  {
    path: '/apps/site-setting/dynamic-pages',
    name: 'apps-site-setting-dynamic-pages',
    component: () => import('@/views/apps/site-setting/dynamic-pages.vue'),
  },
  {
    path: '/apps/dynamic-pages/create',
    name: 'apps-site-setting-create-page',
    component: () => import('@/views/apps/dynamic-pages/DynamicPageCreate.vue'),
  },
  {
    path: '/apps/dynamic-pages/update/:id',
    name: 'apps-site-setting-update-page',
    component: () => import('@/views/apps/dynamic-pages/DynamicPageEdit.vue'),
  },
  {
    path: '/apps/site-setting/coupon/manage-coupons',
    name: 'apps-site-setting-coupon-manage-coupons',
    component: () => import('@/views/apps/coupons/coupons.vue'),
  },
  {
    path: '/apps/site-setting/coupon/create-coupon',
    name: 'apps-site-setting-coupon-create-coupon',
    component: () => import('@/views/apps/coupons/create-coupon.vue'),
  },
  {
    path: '/apps/site-setting/coupon/update/:id',
    name: 'apps-site-setting-coupon-update',
    component: () => import('@/views/apps/coupons/update-coupon.vue'),
  },
  {
    path: '/apps/site-setting/stripe-setting',
    name: 'apps-site-setting-stripe-setting',
    component: () => import('@/views/apps/site-setting/stripe-setting.vue'),
  },
  {
    path: '/apps/site-setting/dynamic-pages',
    name: 'apps-site-setting-dynamic-setting',
    component: () => import('@/views/apps/site-setting/dynamic-pages.vue'),
  },

]
