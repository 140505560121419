import Vue from 'vue'
import mainBaseUrl from '@/global-components'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: mainBaseUrl || 'https://skycoachapi.oregonserver.com/api/v1/',
  // timeout: 10000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
