// V 2.0.3

import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import mainBaseUrl from '@/global-components'

class WebServiceRequest {
  static baseUrl = mainBaseUrl

  constructor(context) {
    this.context = context
    this.data = {}
    this.queryUrl = {}
    this.method = 'POST'
    this.service = ''
    this.userToken = useJwt.getToken()
  }

  getData() {
    return this.data
  }

  setTag(tag) {
    this.tag = tag
  }

  setMethod(method) {
    this.method = method
  }

  setService(service) {
    this.service = service
  }

  getTag() {
    return this.tag
  }

  setRequestParam(value) {
    let tempVal = JSON.parse(JSON.stringify(value))
    if (tempVal) {
      Object.keys(tempVal)
        .forEach((item) => {
          if (tempVal[item] == null || tempVal[item] == undefined) {

            delete tempVal[item]
          }
        })
    }
    this.queryUrl = tempVal
  }

  convertDataToQueryString() {
    return Object.keys(this.queryUrl)
      .map(key => key + '=' + encodeURI(this.queryUrl[key]))
      .join('&')
  }

  setRequestParamDataObj(value) {
    let deepCloned = JSON.parse(JSON.stringify(value))
    Object.keys(deepCloned)
      .forEach((item) => {
        if (
          !deepCloned[item] &&
          deepCloned[item] !== false &&
          deepCloned[item] !== 0
        ) {
          delete deepCloned[item]
        }
      })
    this.data = deepCloned
  }

  setFormData(data) {
    this.formData = data
  }

  getFormData() {
    return this.formData
  }

  getUrl() {
    if (this.queryUrl && Object.keys(this.queryUrl).length > 0) {
      let query_string = this.convertDataToQueryString()
      return WebServiceRequest.baseUrl + this.service + 'api/v1/' + this.tag + '?' + query_string
    } else {
      return WebServiceRequest.baseUrl + this.service + 'api/v1/' + this.getTag()
    }
  }

  async fetch(successCallback, errorCallback) {
    const _this = this
    try {
      const _data = await axios({
        url: _this.getUrl(),
        data: _this.getData(),
        method: _this.method,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + _this.userToken,
        },
        withCredentials: true
      })
        .then(async function (response) {
          return response.data ? response.data : response
        })
      if (_data.isSuccess) {
        successCallback(_data)
      } else {
        errorCallback(_data)
      }
    }
    catch (e) {
      if (e.response.status === 503) {
        _this.context.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `اخطار`,
            icon: '',
            variant: 'danger',
            text: `something went wrong please try later.`,
          },
        })
        _this.context.$router.push('/')
      }
      if (e.response.status === 400) {
        throw Error(e.response.data.message)
        // _this.context.$toast({
        //   component: ToastificationContent,
        //   position: 'bottom-center',
        //   props: {
        //     title: `warning`,
        //     icon: '',
        //     variant: 'danger',
        //     text: e.response.data.message,
        //   },
        // })
      }
      if (e.response.status === 500) {
        _this.context.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `warning`,
            icon: '',
            variant: 'danger',
            text: `something went wrong, please try later`,
          },
        })
        // _this.context.$router.back();
      }
      if (e.response.status === 401) {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        // Remove userData from localStorage
        localStorage.removeItem('userData')
        // Reset ability
        _this.context.$ability.update(initialAbility)
        // Redirect to login page
        _this.context.$router.push({ name: 'auth-login' })
      }
    }
    finally {
      if (this.context) {
        if (this.context.overlay) {
          this.context.overlay = false
        } else if (this.context.showOverlay) {
          this.context.showOverlay = false
        }
      }
    }
  }

  async sendFormData(successCallback, errorCallback) {
    const _this = this
    try {
      const _data = await axios({
        url: _this.getUrl(),
        data: _this.getFormData(),
        method: _this.method,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + _this.userToken,
        },
        withCredentials: true
      })
        .then(function (response) {
          return response.data ? response.data : response
        })
      if (_data.isSuccess) {
        successCallback(_data)
      } else {
        errorCallback(_data)
      }
    }
    catch (e) {
      console.error(e)
      if (e.response.status === 503) {
        _this.context.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `اخطار`,
            icon: '',
            variant: 'danger',
            text: `something went wrong please try later.`,
          },
        })
        _this.context.$router.push('/')
      }
      if (e.response.status === 400) {
        _this.context.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `اخطار`,
            icon: '',
            variant: 'danger',
            text: `اطلاعات وارد شده صحیح نمیباشند.`,
          },
        })
      }
      if (e.response.status === 500) {
        _this.context.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `اخطار`,
            icon: '',
            variant: 'danger',
            text: `something went wrong please try later.`,
          },
        })
        _this.context.$router.back()
      }
      if (e.response.status === 401) {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        // Remove userData from localStorage
        localStorage.removeItem('userData')
        // Reset ability
        _this.context.$ability.update(initialAbility)
        // Redirect to login page
        _this.context.$router.push({ name: 'auth-login' })
      }
    }
  }
}

export default WebServiceRequest
